import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Share: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 18" className={clss}>
            <path
                d="M12.862 11.399c-.968 0-1.832.462-2.385 1.175L5.851 9.99A3.02 3.02 0 0 0 5.847 8l4.623-2.581a3.006 3.006 0 0 0 2.389 1.182 3.016 3.016 0 0 0 3.01-3.011A3.013 3.013 0 0 0 12.86.579a3.013 3.013 0 0 0-2.84 4.008L5.4 7.167a2.996 2.996 0 0 0-2.388-1.182A3.016 3.016 0 0 0 0 8.997a3.011 3.011 0 0 0 5.407 1.825l4.623 2.584a2.998 2.998 0 0 0-.175 1.004 3.016 3.016 0 0 0 3.01 3.011 3.013 3.013 0 0 0 3.012-3.011 3.016 3.016 0 0 0-3.015-3.011zm0-9.872c1.14 0 2.067.926 2.067 2.066 0 1.14-.927 2.067-2.067 2.067a2.069 2.069 0 0 1-2.066-2.067c0-1.14.93-2.066 2.066-2.066zm-9.847 9.536A2.069 2.069 0 0 1 .948 8.997c0-1.14.926-2.067 2.067-2.067 1.14 0 2.066.926 2.066 2.067a2.07 2.07 0 0 1-2.066 2.066zm9.847 5.41a2.069 2.069 0 0 1-2.066-2.066c0-1.14.926-2.067 2.066-2.067 1.14 0 2.067.926 2.067 2.067 0 1.14-.927 2.066-2.067 2.066z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
};

export default Share;
